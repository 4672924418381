/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { GrCaretNext, GrCaretPrevious } from "react-icons/gr";

const Hero = () => {
  return (
    <section className='pt-24 bg-white'>
      <div className='px-12 mx-auto max-w-7xl'>
        <div className='w-full mx-auto text-left md:w-11/12 xl:w-9/12 md:text-center'>
          <h1 className='mb-8 text-4xl font-extrabold leading-none tracking-normal text-gray-900 md:text-3xl md:tracking-tight'>
            <span>Firstly, for brief introduction,</span>{" "}
            <span className='block w-full py-2 text-transparent bg-clip-text leading-12 bg-gradient-to-r from-[#d2ab67] to-[#b02a30] lg:inline'>
              EXCELLA SOURCING
            </span>{" "}
            <span>is an Islamabad - Pakistan</span>
          </h1>
          <h2 className='px-0 mb-8 text-lg text-gray-600 md:text-xl lg:px-24'>
            {" "}
            General Order Supplier & Service Provider focused in the field of
            Electrical, IT, Mechanical, Lab, Electromedical, Industrial
            Equipment, Surgical Consumable & Disposable items, Furniture &
            Fixtures, Printing & Stationery, Established in Islamabad.
          </h2>
          <p className='px-0 mb-8 text-lg text-gray-600 md:text-xl lg:px-24'>
            Our company is duly registered with FBR for Income Tax & Sales Tax,
            Member of Islamabad Chamber of Commerce & Industries, Excise and
            Taxation Department Islamabad for Professional Tax and Pakistan
            Customs for Import of Machinery and Equipment.
          </p>

          <div className='mb-4 space-x-0 md:space-x-2 md:mb-8 flex justify-center max-w-sm m-auto'>
            <a
              href='mailto:info@excellasourcing.com'
              className='btns m-auto max-w-52 '>
              <span className='flex justify-normal flex-row items-center'>
                Get Started
                <svg
                  className='w-4 h-4 ml-1 inline-block'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                  fill='currentColor'>
                  <path
                    fillRule='evenodd'
                    d='M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z'
                    clipRule='evenodd'
                  />
                </svg>{" "}
              </span>
            </a>
            <Link to='/contact' className='btns m-auto max-w-52 '>
              <span className='flex justify-normal flex-row items-center'>
                Contact Us
                <svg
                  className='w-4 h-4 ml-1 inline-block'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z'
                  />
                </svg>{" "}
              </span>
            </Link>
          </div>
        </div>
        <div className='w-full mx-auto mt-20 text-center md:w-10/12'>
          <div className='relative z-0 w-full mt-8'>
            <div className='relative overflow-hidden '>
              <div className='flex items-center flex-none px-4 bg-[#d2ab67] rounded-b-none h-11 rounded-xl'>
                <div className='flex space-x-1.5'>
                  <div className='w-3 h-3 border-2 border-white rounded-full' />
                  <div className='w-3 h-3 border-2 border-white rounded-full' />
                  <div className='w-3 h-3 border-2 border-white rounded-full' />
                </div>
              </div>
              <div
                id='carouselExampleInterval'
                className='carousel slide'
                data-bs-ride='carousel'>
                <div className='carousel-indicators'>
                  <button
                    type='button'
                    data-bs-target='#carouselExampleInterval'
                    data-bs-slide-to={0}
                    className='active'
                    aria-current='true'
                    aria-label='Slide 1'
                  />
                  <button
                    type='button'
                    data-bs-target='#carouselExampleInterval'
                    data-bs-slide-to={1}
                    aria-label='Slide 2'
                  />
                  <button
                    type='button'
                    data-bs-target='#carouselExampleInterval'
                    data-bs-slide-to={2}
                    aria-label='Slide 3'
                  />
                  <button
                    type='button'
                    data-bs-target='#carouselExampleInterval'
                    data-bs-slide-to={3}
                    aria-label='Slide 4'
                  />
                </div>
                <div className='carousel-inner'>
                  <div className='carousel-item active' data-bs-interval='2000'>
                    <img
                      src='assets/medhero.jpg'
                      className='d-block w-100'
                      alt='...'
                    />
                    <div className='carousel-caption d-none d-md-block backdrop-blur-sm rounded-md'>
                      <h2>Medical Equipments</h2>
                    </div>
                  </div>
                  <div className='carousel-item' data-bs-interval='2000'>
                    <img
                      src='assets/Labhero.jpg'
                      className='d-block w-100'
                      alt='...'
                    />
                    <div className='carousel-caption d-none d-md-block   backdrop-blur-sm rounded-md '>
                      <h2>Laboratory Equipments</h2>
                    </div>
                  </div>
                  <div className='carousel-item' data-bs-interval='2000'>
                    <img
                      src='assets/indhero.jpg'
                      className='d-block w-100'
                      alt='...'
                    />
                    <div className='carousel-caption d-none d-md-block  backdrop-blur-sm rounded-md'>
                      <h2 className='text-gray-100 '>Industrial Products</h2>
                    </div>
                  </div>
                  <div className='carousel-item' data-bs-interval='2000'>
                    <img
                      src='assets/genhero.jpg'
                      className='d-block w-100'
                      alt='...'
                    />
                    <div className='carousel-caption d-none d-md-block  backdrop-blur-sm rounded-md'>
                      <h2 className='text-gray-100 '>General Products</h2>
                    </div>
                  </div>
                </div>
                <button
                  className='carousel-control-prev'
                  type='button'
                  data-bs-target='#carouselExampleInterval'
                  data-bs-slide='prev'>
                  <GrCaretPrevious />
                </button>
                <button
                  className='carousel-control-next '
                  type='button'
                  data-bs-target='#carouselExampleInterval'
                  data-bs-slide='next'>
                  <GrCaretNext />
                </button>
              </div>

              {/* <img src='' /> */}
            </div>
          </div>
        </div>
        <div className='w-full mx-auto my-20 text-center md:w-10/12'>
          <h1 className='mb-8 text-4xl font-extrabold leading-none tracking-normal text-gray-900 md:text-3xl md:tracking-tight'>
            <span className='block w-full py-2 text-transparent bg-clip-text leading-12 bg-gradient-to-r from-[#d2ab67] to-[#b02a30] lg:inline'>
              ISO-9001 ISO-13485
            </span>{" "}
            <br />
            <span className=' capitalize'>
              manufacturer, importer, exporter and supplier
            </span>
          </h1>
          <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 '>
            <div className=' max-w-[400px]  shadow-xl flex justify-center items-center  transition-all '>
              <img
                className=' w-4/4 hover:scale-125  transition-all aspect-square object-contain rounded-md'
                src='/assets/certficate-1.png'
                alt='gallery'
              />
            </div>
            <div className=' max-w-[400px]  shadow-xl flex justify-center items-center  transition-all '>
              <img
                className=' w-4/4 hover:scale-125  transition-all aspect-square object-contain rounded-md'
                src='/assets/certficate-2.png'
                alt='gallery'
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
